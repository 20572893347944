<template>
  <div>
    <nav class="navbar navbar-dark bg-dark">
      <div class="container-fluid">
        <a class="navbar-brand" href="/">
          <img src="@/assets/media/logo.png" width="100px"/>
        </a>
      </div>
    </nav>

    <div class="main-content p-4">
      <div class="container-lg">
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: '',
  props: {},
  data() {
    return {}
  }
}
</script>
<style lang="scss" scoped>

</style>
