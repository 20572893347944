<template>
  <div>
    <AppLayout>
        <router-view />
    </AppLayout>
  </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout.vue";
export default {
  components: {
    AppLayout
  }
}
</script>

<style lang="scss">
body {
  min-height: 100vh;
  background: #f5f4f3 !important;
}

main {
  display: flex;
  flex-wrap: nowrap;
  height: 100vh;
  max-height: 100vh;
  overflow-x: auto;
  overflow-y: hidden;
}
</style>