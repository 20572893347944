import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:event_slug/:id',
    name: 'Home',
    component: Home
  },
  {
    path: '/404',
    name: '404',
    component: () => import( '../views/404.vue' ),
  },
  { path: '*', redirect: '/404' },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
