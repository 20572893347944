<template>
    <div>
        <loading :enable="loading"/>
        <div class="event-banner">
            <!--        <img :src="event.banner_url" alt="Banner do Evento"/>-->
            <picture>
                <source srcset="@/assets/media/identidade.png" media="(min-width: 640px)">
                <img src="@/assets/media/identidade-xs.png">
            </picture>
        </div>

        <div class="row mt-4">
            <div class="col-12">
                <h3>Maio, 25</h3>
                <h2>{{ event.name }}</h2>
                <p class="event-description" v-html="event.description"></p>
            </div>

            <div class="col-12">
                <div class="d-flex align-items-center justify-content-center justify-content-md-start">
                    <button class="btn btn-primary cta" data-bs-toggle="modal" data-bs-target="#modalSubscription"
                            @click="clearModal">Fazer
                        Inscrição
                    </button>
                </div>
            </div>

            <!-- Modal -->
            <div
                    class="modal fade"
                    id="modalSubscription"
                    data-bs-backdrop="static"
                    data-bs-keyboard="false"
                    tabindex="-1"
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
            >
                <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-xl modal-fullscreen-lg-down">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title" id="exampleModalLabel">Nova inscrição</h5>
                            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"
                                    @click="clearModal" v-if="!paymentLink && success"></button>
                        </div>
                        <div class="modal-body">
                            <div class="container-fluid" v-if="!success">

                                <h4 class="card-title text-primary">Inscrições</h4>
                                <div class="row g-2 mt-2" v-for="(item, index) in $v.order.items.$each.$iter">
                                    <div class="d-flex align-items-center justify-content-between">
                                        <span class="text-primary">#Inscrito numero {{ parseInt(index) + 1 }}</span>
                                        <button v-if="index > 0" class="btn btn-danger btn-sm text-white"
                                                @click.prevent="removeItem(item)">
                                            <i class="la la-trash text-white"></i> Remover
                                        </button>
                                    </div>

                                    <div class="col-12 col-lg-4">
                                        <div>
                                            <label class="form-label">Nome Completo</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="item.name.$model"
                                                    :class="{'is-invalid' : item.name.$error }"
                                            />
                                        </div>
                                    </div>

                                    <div class="col-6 col-lg-3">
                                        <div>
                                            <label class="form-label">Telefone</label>
                                            <input type="text" v-mask="['(##) ####-####', '(##) #####-####']" class="form-control" v-model.trim="item.phone.$model">
                                        </div>
                                    </div>

                                    <div class="col-6 col-lg-3">
                                        <div>
                                            <label class="form-label">Dt. Nascimento</label>
                                            <input
                                                    type="date"
                                                    class="form-control"
                                                    v-model.trim="item.birthday.$model"
                                                    @blur="getPrice(item, $event.target.value)"
                                                    :class="{'is-invalid' : item.birthday.$error }"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-2">
                                        <div>
                                            <label class="form-label">Valor</label>
                                            <input type="text" disabled class="form-control"
                                                   v-model.trim="item.amount.$model">
                                        </div>
                                    </div>

                                    <hr class="my-4"/>
                                </div>

                                <button class="btn btn-primary text-white btn-sm" @click.prevent="addItem">Adicionar
                                    novo inscrito
                                </button>

                                <h4 class="card-title text-primary mt-3">Dados do pagador/Responsável</h4>
                                <div class="row g-2 mt-2">
                                    <div class="col-12 col-lg-5">
                                        <div>
                                            <label class="form-label">Nome Completo</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="$v.order.payer_name.$model"
                                                    :class="{'is-invalid': $v.order.payer_name.$error}"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-3">
                                        <div>
                                            <label class="form-label">CPF</label>
                                            <input
                                                    type="text"
                                                    class="form-control"
                                                    v-model="$v.order.payer_document.$model"
                                                    :class="{'is-invalid': $v.order.payer_document.$error}"
                                                    v-mask="['###.###.###-##']"
                                            >
                                        </div>
                                    </div>

                                    <div class="col-12 col-lg-4">
                                        <div>
                                            <label class="form-label">E-mail</label>
                                            <input
                                                    type="email"
                                                    class="form-control"
                                                    v-model="$v.order.payer_email.$model"
                                                    :class="{'is-invalid': $v.order.payer_email.$error}"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="container-fluid" v-if="success">
                                <div class="d-flex align-items-center justify-content-center">
                                    <i class="la la-check-circle la-5x text-success"></i>
                                </div>
                                <h4 class="text-center mt-4">Inscrições registradas com sucesso!</h4>
                                <h3 class="text-center" v-if="paymentLink">Clique no botão abaixo para prosseguir para o
                                    pagamento</h3>
                                <h3 class="text-center" v-else>A confirmação foi enviada para seu e-mail</h3>

                                <div class="d-flex align-items-center justify-content-center mt-4 mb-4"
                                     v-if="paymentLink">
                                    <button class="btn btn-success text-white" @click="pay">
                                        <i class="la la-money-bill me-2"></i>
                                        Prosseguir para o pagamento
                                    </button>
                                </div>

                            </div>
                        </div>
                        <div class="modal-footer" v-if="!success">
                            <button type="button" class="btn btn-success text-white" @click.prevent="submit">Fazer
                                inscrição
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-12 mt-4">
                <h4>Quando e onde</h4>
                <div class="mt-4">
                    <div class="d-flex">
                        <i class="la la-calendar la-2x text-primary"></i>
                        <div class="ms-2 d-flex flex-column">
                            <span class="fw-bold">Data e Hora</span>
                            <span class="text-secondary">{{ when.toLocaleString('pt-Br') }}</span>
                        </div>
                    </div>
                </div>

                <div class="mt-4">
                    <div class="d-flex">
                        <i class="la la-calendar la-2x text-primary"></i>
                        <div class="ms-2 d-flex flex-column">
                            <span class="fw-bold">Localização</span>
                            <div class="d-flex flex-column">
                                <strong class="text-secondary">Comunidade Cristã Ruah</strong>
                                <span class="text-secondary">{{ event.local }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import {email, minLength, required} from "vuelidate/lib/validators";
import Loading from "@/components/loading.vue";
import {mask} from 'vue-the-mask'

export default {
    name: 'home',
    components: {Loading},
    directives: {
        mask
    },
    data() {
        return {
            loading: false,
            success: false,
            paymentLink: null,
            event: {},
            order: {
                payer_name: null,
                payer_document: null,
                payer_email: null,
                items: [
                    {
                        name: '',
                        phone: '',
                        birthday: '',
                        amount: ''
                    },
                ]
            }
        }
    },
    validations: {
        order: {
            payer_name: {required},
            payer_document: {required},
            payer_email: {required, email},
            items: {
                required,
                minLength: minLength(1),
                $each: {
                    name: {
                        required,
                        minLength: minLength(5)
                    },
                    birthday: {
                        required
                    },
                    phone: {},
                    amount: {}
                }
            },

        }
    },
    mounted() {
        this.getEvent()
    },
    methods: {
        async getEvent() {
            this.loading = true;
            await this.$http.get(`/conferences/${this.$route.params.id}`).then(response => {
                this.event = response.data
            }).catch(error => {
                this.$router.push('/404');
            }).finally(() => {
                this.loading = false;
            })
        },

        addItem() {
            this.$v.order.items.$touch();

            if (this.$v.order.items.$invalid) {
                return false
            }

            this.order.items.push({
                name: null,
                phone: null,
                birthday: null,
                amount: null
            });
        },
        removeItem(item) {
            this.order.items.splice(this.order.items.indexOf(item), 1);
        },
        async getPrice(item, birthday) {
            if (!birthday) {
                item.amount.$model = null
                return false;
            }
            this.loading = true;
            await this.$http.post(`/conferences/${this.$route.params.id}/price`, {birthday: birthday}).then(response => {
                item.amount.$model = parseFloat(response.data).toFixed(2);
            }).finally(() => {
                this.loading = false;
            })
        },
        async submit() {
            this.$v.order.$touch();

            if (this.$v.order.$invalid) {
                return false
            }

            this.loading = true;
            await this.$http.post(`/conferences/${this.$route.params.id}/order`, this.order).then(response => {
                this.paymentLink = response.data.payment_link;
                this.success = true;
            }).catch(error => {
                this.$swal.fire({
                    icon: 'error',
                    title: 'Opps, algo deu errado....',
                    text: error.response.data.message
                })
            }).finally(() => {
                this.loading = false;
            })
        },
        clearModal() {
            this.order = {
                payer_name: null,
                payer_document: null,
                payer_email: null,
                items: [
                    {
                        name: '',
                        phone: '',
                        birthday: '',
                        amount: ''
                    },
                ]
            };
            this.success = false;
            this.$v.$reset();

        },
        pay() {
            window.open(this.paymentLink, '_self');
        }
    },
    computed: {
        when() {
            if (!this.event.start_at) {
                return new Date();
            }

            return new Date(this.event.start_at);
        }
    }
}
</script>
<style scoped lang="scss">
h3 {
  font-size: 1rem;
  font-weight: 500;
}

h2 {
  font-size: 2rem;
  font-weight: 700;
}

h4 {
  font-weight: 700;
}

.cta {
  width: 200px;
  color: #fff;
  height: 50px;
  font-weight: 500;
}

.event-banner {
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: .32rem;
  overflow: hidden;

  img {
    position: relative;
    width: 100%;
    height: auto;
    z-index: 1;
  }
}

.event-description {
  white-space: pre-line;
}
</style>
