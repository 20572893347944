<template>
    <div id="hover" v-if="enable" class="d-flex justify-content-center align-items-center">
        <div class="d-flex justify-content-center">
            <div class="spinner-grow text-primary" role="status">
                <span class="visually-hidden">Carregando...</span>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        enable: {
            type: Boolean,
            required: true,
            default: true,
        },
    },
};
</script>

<style lang="css">
#hover {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(255, 255, 255, 0.9);
    z-index: 999999999;

}

#hover img {
    width: 100px;
    height: 100px;
}
</style>
