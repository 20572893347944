import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import {dateFilter} from "vue-date-fns";
import Vuelidate from 'vuelidate'
import {serialize} from "object-to-formdata";
import VueSweetalert2 from 'vue-sweetalert2';

import AppLayout from "@/layouts/AppLayout.vue";

import "@/assets/sass/app.scss"
import "bootstrap"
import 'sweetalert2/dist/sweetalert2.min.css';
import axios from "axios";

Vue.component('app', AppLayout);
Vue.filter("date", dateFilter);
Vue.use(Vuelidate);
Vue.use(VueSweetalert2);

Vue.config.productionTip = false

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
});
Vue.prototype.$http = axiosInstance;

Vue.prototype.$serialize = function (object) {
  return serialize(object, {
      indices: true,
      booleansAsIntegers: true,
      allowEmptyArrays: false,
  })
};

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
